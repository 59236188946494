import React, { ReactElement, useEffect } from "react";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

const { host } = window.location;

export const environment = {
  hosts: {
    default: {
      host: "default",
      maisonMereUrl:
        "https://www.tutelaire.fr/offre/contrat-sage-autonomie/sage-autonomie",
      title: "Tutelaire",
      gtmId: process.env.VITE_TUTELAIRE_GTM_ID,
      docDIPA:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/DIPA_tutelaire.pdf",
      docReglement:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/REGLEMENT_tutelaire.pdf",
      docStatut:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/STATUT_tutelaire.pdf",
    },
    MSP: {
      host: process.env.VITE_MSP_HOST,
      maisonMereUrl: `http://${process.env.VITE_MSP_HOST}`,
      title: "Mutuelle des Services Publics",
      gtmId: process.env.VITE_MSP_GTM_ID,
      docDIPA:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/DIPA_MSP.pdf",
      docReglement:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/REGLEMENT_msp.pdf",
      docStatut:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/STATUT_tutelaire.pdf",
    },
    MCA: {
      host: process.env.VITE_MCA_HOST,
      maisonMereUrl: `http://${process.env.VITE_MCA_HOST}`,
      title: "Mutuelle Complémentaire d'Alsace",
      gtmId: process.env.VITE_MCA_GTM_ID,
      docDIPA:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/DIPA_MCA.pdf",
      docReglement:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/REGLEMENT_mca.pdf",
      docStatut:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/STATUT_tutelaire.pdf",
    },
    MUTAMI: {
      host: process.env.VITE_MUTAMI_HOST,
      maisonMereUrl: `http://${process.env.VITE_MUTAMI_HOST}`,
      title: "Mutami, mutuelle santé",
      gtmId: process.env.VITE_MUTAMI_GTM_ID,
      docDIPA:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/DIPA_MUTAMI.pdf",
      docReglement:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/REGLEMENT_mutami.pdf",
      docStatut:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/STATUT_tutelaire.pdf",
    },
    AMELLIS: {
      host: process.env.VITE_AMELLIS_HOST,
      maisonMereUrl: `http://${process.env.VITE_AMELLIS_HOST}`,
      title: "Amellis, mutuelle santé",
      gtmId: process.env.VITE_AMELLIS_GTM_ID,
      docDIPA:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/DIPA_AMELLIS.pdf",
      docReglement:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/REGLEMENT_amellis.pdf",
      docStatut:
        "https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/STATUT_tutelaire.pdf",
    }
  },

  get is(): Record<keyof typeof this.hosts, boolean> {
    return Object.keys(this.hosts).reduce((acc, key) => {
      return { ...acc, [key]: key === this.name };
    }, {} as Record<keyof typeof this.hosts, boolean>);
  },

  get name(): keyof typeof this.hosts {
    return Object.entries(this.hosts).reduce<keyof typeof this.hosts>(
      (acc, [key, value]) => {
        return host === value.host ? (key as keyof typeof this.hosts) : acc;
      },
      "default"
    );
  },

  get current(): typeof this.hosts.default {
    return this.hosts[this.name];
  },
};

type AppProps = {
  children?: React.ReactNode;
};

export function MarqueBlanche({ children }: AppProps): ReactElement {
  useEffect(() => {
    const root = document.documentElement;

    switch (environment.name) {
      case "MSP":
        root.style.setProperty("--accent-h", "3");
        root.style.setProperty("--accent-s", "69%");
        root.style.setProperty("--accent-l", "49%");
        root.style.setProperty("--accent-secondary-h", "36");
        root.style.setProperty("--accent-secondary-s", "100%");
        root.style.setProperty("--accent-secondary-l", "47%");
        break;

      case "MCA":
        root.style.setProperty("--accent-h", "45");
        root.style.setProperty("--accent-s", "100%");
        root.style.setProperty("--accent-l", "51%");
        root.style.setProperty("--accent-secondary-h", "355");
        root.style.setProperty("--accent-secondary-s", "75%");
        root.style.setProperty("--accent-secondary-l", "37%");
        break;

      case "MUTAMI":
        root.style.setProperty("--accent-h", "326");
        root.style.setProperty("--accent-s", "76%");
        root.style.setProperty("--accent-l", "46%");
        root.style.setProperty("--accent-secondary-h", "205");
        root.style.setProperty("--accent-secondary-s", "93%");
        root.style.setProperty("--accent-secondary-l", "36%");
        break;

      case "AMELLIS": 
        root.style.setProperty("--accent-h", "207");
        root.style.setProperty("--accent-s", "90%");
        root.style.setProperty("--accent-l", "25%");
        root.style.setProperty("--accent-secondary-h", "45");
        root.style.setProperty("--accent-secondary-s", "97%");
        root.style.setProperty("--accent-secondary-l", "50%");
        break;

      default:
        root.style.setProperty("--accent-h", "183");
        root.style.setProperty("--accent-s", "100%");
        root.style.setProperty("--accent-l", "24%");
        root.style.setProperty("--accent-secondary-h", "61");
        root.style.setProperty("--accent-secondary-s", "100%");
        root.style.setProperty("--accent-secondary-l", "39%");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{environment.current.title}</title>
        {environment.is.default && (
          <link rel="icon" href="/logo-min-tutelaire.png" />
        )}
        {environment.is.MSP && <link rel="icon" href="/favicon-MSP.png" />}
        {environment.is.MCA && <link rel="icon" href="/favicon-MCA.png" />}
        {environment.is.MUTAMI && <link rel="icon" href="/icon-MUTAMI.png" />}
        {environment.is.AMELLIS && <link rel="icon" href="/icon-AMELLIS.png" />}
        {environment.current.gtmId && (
          <script
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${environment.current.gtmId}');
                `),
            }}
          />
        )}
      </Helmet>
      {children}
    </>
  );
}
